import { initializeApp } from "firebase/app";
import { getDatabase, ref, push, onValue, serverTimestamp, set, get, orderByChild } from "firebase/database";
import { query } from "firebase/firestore";

import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyBpxBUsqogglpNrdE_JXxRDq7zeiIindps",
  authDomain: "akshaygyan-5befb.firebaseapp.com",
  databaseURL: "https://akshaygyan-5befb-default-rtdb.firebaseio.com",
  projectId: "akshaygyan-5befb",
  storageBucket: "akshaygyan-5befb.appspot.com",
  messagingSenderId: "552196613068",
  appId: "1:552196613068:web:6d5af43a3158f30bd7b369",
  measurementId: "G-0DCGZGJMQ1"
};


//! Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

//! Initialize Firebase Cloud Messaging and get a reference to the service
const messaging = getMessaging(app);

export { database, ref, push, onValue, serverTimestamp, set, messaging, get, orderByChild, query };